/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useModal } from './common/modal';
import { Flex } from './common/styles/flex';

import useI18n from '../lib/use_i18n';

import styled from 'styled-components';
import { fontSizeL } from './common/styles/variables';
import LocaleSelector from './common/locale_selector';

const AcceptBtn = styled.button`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 13px;
`;

const Msg = styled.div`
  font-size: ${fontSizeL};
  color: #444;

  p {
    margin-bottom: 13px;
  }
`;

/**
 * Displays a popup allowing users to give informed consent for the
 * use of tracking cookies by the website.
 */
const CookieBanner = ({
  furtherInformationUrl,
  availableLocales,
  currentLocale,
}) => {
  const { translate } = useI18n('cookies');

  const { Modal, close, open } = useModal();

  // Access the CookieConsent lib via the window object.
  // This gives us access to the instance where tracking code is registered,
  // and avoids importing the lib into this javascript pack.
  const CookieConsent = window.CookieConsent;

  const acceptCookies = () => {
    CookieConsent.acceptCookies();
    close();
  };

  const rejectCookies = () => {
    CookieConsent.rejectCookies();
    close();
  };

  useEffect(() => {
    if (CookieConsent && CookieConsent.showBanner()) {
      open();
    }
  }, []);

  return (
    <Modal hideX={true} id="cookie-banner" hideHeader={true}>
      <Msg>
        <p>{translate('.warning')}</p>
        <Flex justifyContent="space-between">
          <div>
            <a href={furtherInformationUrl}>
              {translate('.further_information')}
            </a>
          </div>
          <div>
            <LocaleSelector
              currentLocale={currentLocale}
              availableLocales={availableLocales}
            />
          </div>
        </Flex>
      </Msg>
      <Row>
        <AcceptBtn
          onClick={acceptCookies}
          id="cookie-banner-accept"
          href="#"
          className="accept btn btn-primary"
        >
          {translate('.accept')}
        </AcceptBtn>
      </Row>
      <Row>
        <a onClick={rejectCookies} id="cookie-banner-reject" href="#">
          {translate('.reject')}
        </a>
      </Row>
    </Modal>
  );
};

CookieBanner.propTypes = {
  furtherInformationUrl: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  availableLocales: PropTypes.array.isRequired,
};

export default CookieBanner;
